export class FaqModel {
    id: number;
    status: string;
    service_id: number;
    created_by: number;
    created_at: Date;
    updated_at: Date;
    answers: FaqAnswerModel[];
  }
  
  export class FaqAnswerModel {
    id: number;
    faq_id: number;
    created_at: Date;
    updated_at: Date;
    answer_translations: FaqAnswerTranslationModel[];
  }
  
  export class FaqAnswerTranslationModel {
    id: number;
    answer_id: number;
    language_id: number;
    answer: string;
    created_at: Date;
    updated_at: Date;
    questions: FaqQuestionModel[];
  }
  
  export class FaqQuestionModel {
    id: number;
    answer_id: number;
    created_at: Date;
    updated_at: Date;
    question_translations: FaqQuestionTranslationModel[];
  }
  
  export class FaqQuestionTranslationModel {
    id: number;
    question_id: number;
    language_id: number;
    question: string;
    created_at: Date;
    updated_at: Date;
  }
  
  export const FAQStatus = {
    'DRAFT': { label: 'Rascunho', color: '#808080'},
    'ACTIVE': { label: 'Ativo', color: 'green'},
    'INACTIVE': { label: 'Desativado', color: 'red'},
  }
  
  export class FAQConfig {
    CHATBOT_STATUS: boolean;
    CHATBOT_NAME: string;
    CHATBOT_CONTACTS: string;
    MAX_CHARACTERS: any;
    MIN_CHARACTERS: any;
  }