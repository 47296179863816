import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ChatbotService } from '../../services/chatbot.service';
import { FiConfigurator } from '@fi-sas/webpage/libs/configurator';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { ConfigurationsService } from '@fi-sas/webpage/shared/services/configurations.service';
import { FAQConfig } from '../../models/chatbot.model';

interface Message {
  sender: 'user' | 'bot';
  text: string;
  buttons?: { id: any; name: string }[];
}

@Component({
  selector: 'fisas-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.less'],
})
export class ChatbotComponent implements OnInit {
  isLoading: boolean = false;
  isChatButtonVisible: boolean = false;
  isInputDisabled: boolean = false;
  messages: Message[] = [];
  userMessage: string = '';

  currentLang = {};
  currentLangId: number | null = null;
  languages: any;

  chatbotFaqsData: any = null;
  chatbotActive: boolean = false;

  MIN_MESSAGE_LENGTH: number;
  MAX_MESSAGE_LENGTH: number;
  CHATBOT_NAME: string;

  readonly serviceId: number = 39;

  constructor(
    private configurationsService: ConfigurationsService,
    public chatbotService: ChatbotService,
    private translate: TranslateService,
    private config: FiConfigurator,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.validateService()) {
      this.chatbotService
        .getStoredChatbotConfig()
        .pipe(first())
        .subscribe((storedConfig) => {
          if (storedConfig) {
            this.applyChatbotConfig(storedConfig);
          } else {
            this.chatbotService
              .getChatbotConfig()
              .pipe(first())
              .subscribe((config) => {
                this.applyChatbotConfig(config);
              });
          }
        });
    }
  }

  ngOnDestroy() {
    this.chatbotService.isChatBoxVisible.next(false);
  }

  validateService() {
    const serviceData = this.configurationsService.getLocalStorageService(
      this.serviceId
    );
    if (serviceData) {
      return serviceData.active;
    }
    return false;
  }

  private applyChatbotConfig(config: FAQConfig) {
    this.chatbotActive = config.CHATBOT_STATUS;
    this.MIN_MESSAGE_LENGTH = config.MIN_CHARACTERS || 5;
    this.MAX_MESSAGE_LENGTH = config.MAX_CHARACTERS || 99;
    this.CHATBOT_NAME = config.CHATBOT_NAME || 'Chatbot';

    if (this.chatbotActive) {
      this.loadFAQs();
      this.setupLanguageChangeListener();
      this.getCurrentLanguage();
      this.isChatButtonVisible = true;
    }
  }

  private loadFAQs() {
    this.isLoading = true;
    this.chatbotService.getChatbotFaqs().subscribe({
      next: (res) => {
        this.chatbotFaqsData = res.data;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.showErrorMessage('CHATBOT.ERRORS.FAILED_TO_LOAD_FAQS');
      },
    });
  }

  private setupLanguageChangeListener() {
    this.translate.onLangChange.subscribe(() => this.onLanguageChange());
  }

  getCurrentLanguage() {
    this.languages = Object.values(this.config.getOptionTree('LANGUAGES'))[0];
    this.currentLang = this.translate.currentLang;
    const currentLanguageObj = this.languages.find(
      (lang: { acronym: string; id: number }) =>
        lang.acronym === this.currentLang
    );
    this.currentLangId = currentLanguageObj ? currentLanguageObj.id : null;
  }

  onLanguageChange() {
    this.getCurrentLanguage();
    if (this.chatbotService.isChatBoxVisible.getValue()) {
      this.toggleChatBox();
    }
    this.messages = [];
    this.chatbotService.isBotStarted.next(false);
  }

  toggleChatBox() {
    this.chatbotService.toggleChatBoxVisibility();
    this.isChatButtonVisible = !this.chatbotService.isChatBoxVisible.getValue();

    if (
      this.chatbotService.isChatBoxVisible.getValue() &&
      !this.chatbotService.isBotStarted.getValue()
    ) {
      this.startBot();
    }
  }

  resetChatBot(): void {
    this.chatbotService.resetChatBotState();
    this.isChatButtonVisible = true;
    this.messages = [];
    this.userMessage = '';
  }

  private startBot() {
    this.loadFAQs();
    this.isInputDisabled = true;
    this.chatbotService.isBotStarted.next(true);

    setTimeout(() => {
      this.showTranslatedMessage('CHATBOT.DEFAULT_MESSAGE.WELCOME', 'bot', {
        name: this.CHATBOT_NAME,
      });
    }, 300);

    setTimeout(() => {
      this.displayAvailableServices();
    }, 2000);
  }

  private showTranslatedMessage(
    key: string,
    sender: 'user' | 'bot',
    interpolateParams?: Record<string, any>
  ) {
    this.translate.get(key, interpolateParams).subscribe((message: string) => {
      this.generateMessage(message, sender);
    });
  }

  private showErrorMessage(
    key: string,
    interpolateParams?: Record<string, any>
  ) {
    this.showTranslatedMessage(key, 'bot', interpolateParams);
  }

  async onSubmit(event: Event) {
    event.preventDefault();
    const msg = this.userMessage.trim();

    if (!msg || this.isInputDisabled) return;

    if (
      msg.length < this.MIN_MESSAGE_LENGTH ||
      msg.length > this.MAX_MESSAGE_LENGTH
    ) {
      const key =
        msg.length < this.MIN_MESSAGE_LENGTH
          ? 'CHATBOT.ERRORS.MIN_LENGTH'
          : 'CHATBOT.ERRORS.MAX_LENGTH';
      this.showErrorMessage(key, {
        min: this.MIN_MESSAGE_LENGTH,
        max: this.MAX_MESSAGE_LENGTH,
      });
      return;
    }

    this.isLoading = true;
    this.isInputDisabled = true;
    this.generateMessage(msg, 'user');
    this.userMessage = '';

    try {
      const response = await firstValueFrom(this.getResponse(msg));
      if (!response) {
        this.showErrorMessage('CHATBOT.ERRORS.DEFAULT_ERROR');
        return;
      }
      this.handleResponse(response);
    } finally {
      this.isLoading = false;
      this.isInputDisabled = false;
    }
  }

  private getResponse(messageText: string): Observable<any> {
    return this.chatbotService.getSelectedServiceID().pipe(
      first(),
      switchMap((selectedServiceID) => {
        return this.chatbotService.findBestMatchingFAQ(
          messageText,
          this.currentLangId,
          selectedServiceID
        );
      }),
      catchError(() => of(null)),
      finalize(() => {
        this.isLoading = false;
        this.isInputDisabled = false;
      })
    );
  }

  private handleResponse(response: any) {
    if (response?.error) {
      this.generateMessage(response.error, 'bot');
      setTimeout(() => {
        this.displayAvailableServices();
      }, 2000);
    } else {
      this.generateMessage(response, 'bot');
      setTimeout(() => {
        this.displayChangeService();
      }, 1000);
    }
  }

  generateMessage(
    text: string,
    sender: 'user' | 'bot',
    buttons?: { id: any; name: string }[]
  ) {
    this.messages.push({ sender, text: text.trim(), buttons });
    this.scrollToBottom();
  }

  private scrollToBottom() {
    this.cdRef.detectChanges();
    const chatLogs = document.querySelector('.chat-logs');
    chatLogs?.scrollTo({ top: chatLogs.scrollHeight, behavior: 'smooth' });
  }

  displayAvailableServices() {
    if (!this.chatbotFaqsData) {
      this.showErrorMessage('CHATBOT.ERRORS.NO_FAQS_AVAILABLE');
      return;
    }

    const availableServices = this.chatbotFaqsData
      .map((item) => {
        const serviceTranslation = item.service.translations.find(
          (translation) => translation.language_id === this.currentLangId
        );
        return serviceTranslation
          ? { name: serviceTranslation.name, id: item.service.id }
          : null;
      })
      .filter((service) => service !== null);
    if (availableServices.length > 0) {
      this.showTranslatedMessage('CHATBOT.FAQS.SELECT_SERVICES', 'bot');
      this.messages.push({
        sender: 'bot',
        text: '',
        buttons: availableServices,
      });
      this.scrollToBottom();
    } else {
      this.showErrorMessage('CHATBOT.ERRORS.NO_SERVICES_AVAILABLE');
    }
  }

  displayChangeService() {
    const buttons = [
      { id: 'yes', name: this.translate.instant('CHATBOT.PROMPT.KNOW_MORE') },
      {
        id: 'no',
        name: this.translate.instant('CHATBOT.PROMPT.CHANGE_SERVICE'),
      },
    ];
    this.showTranslatedMessage('CHATBOT.PROMPT.FOLLOW_UP', 'bot');
    this.messages.push({ sender: 'bot', text: '', buttons });
    this.scrollToBottom();
  }

  onButtonSelect(serviceId: string | number, serviceName?: string) {
    if (serviceId === 'yes') {
      this.showTranslatedMessage('CHATBOT.PROMPT.ASK_ANOTHER_QUESTION', 'bot');
      this.isInputDisabled = false;
    } else if (serviceId === 'no') {
      this.messages = [];
      this.chatbotService.setSelectedServiceID(null);
      this.displayAvailableServices();
    } else if (typeof serviceId === 'number') {
      this.isInputDisabled = false;
      this.messages = [];
      this.chatbotService.setSelectedServiceID(serviceId);
      this.showTranslatedMessage('CHATBOT.FAQS.SELECTED_SERVICE', 'bot', {
        service: serviceName,
      });
    } else {
      this.showErrorMessage('CHATBOT.ERRORS.DEFAULT_ERROR');
    }
  }
}
